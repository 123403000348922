var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"users"},[_c('v-card-actions',{staticClass:"pa-0"},[_c('page-title',{attrs:{"title":_vm.title}}),_c('v-spacer')],1),_c('e-facilities',{attrs:{"set-data":_vm.setActive}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.params.total,"options":_vm.pagination,"items-per-page":_vm.params.size,"single-expand":true,"no-data-text":'Data not available in this resource',"footer-props":{
      'items-per-page-options': [20, 50, 100, 300, 500, -1],
    }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-card-title',[_c('v-spacer'),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"6","sm":"12","md":"4"}},[(
              _vm.user &&
                _vm.user.location &&
                _vm.user.location.level &&
                _vm.user.location.level.id != 5
            )?_c('v-text-field',{attrs:{"flat":"","hide-details":"","maxlength":"8","label":'Search by Location',"prepend-icon":"mdi-magnify","readonly":""},on:{"click":_vm.searchUserByLocation},model:{value:(_vm.selectedLoc),callback:function ($$v) {_vm.selectedLoc=$$v},expression:"selectedLoc"}}):_vm._e()],1),_c('v-col',{staticClass:"pl-4",attrs:{"cols":"6","sm":"12","md":"4"}},[_c('SearchField',{attrs:{"search-function":_vm.searchUser,"prepend-icon":'mdi-magnify',"label":'Search Data',"append-icon":''}})],1)],1)]},proxy:true},{key:"item",fn:function(ref){
            var item = ref.item;
            var isExpanded = ref.isExpanded;
            var expand = ref.expand;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.index))]),_c('td',[_vm._v(_vm._s(item.fullName))]),_c('td',[_vm._v(_vm._s(item.gender ? item.gender.name : ""))]),_c('td',[_vm._v(_vm._s(item.NIN))]),_c('td',[_vm._v(_vm._s(item.cheque_number))]),_c('td',[_vm._v(_vm._s(item.birthdate))]),_c('td',[_vm._v(_vm._s(item.email))]),_c('td',[_vm._v(_vm._s(item.location.name))]),_c('td',[_vm._v(_vm._s(item.roles ? item.roles.length : ""))]),_c('td',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){return expand(!isExpanded)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-format-list-bulleted ")])]}}],null,true)},[_c('span',[_vm._v("List of Roles")])])],1),_c('td',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.resetPassword(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-lock-reset ")])]}}],null,true)},[_c('span',[_vm._v("Reset Password")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.openDialog(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil-box-multiple ")])]}}],null,true)},[_c('span',[_vm._v("Update")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"red"},on:{"click":function($event){return _vm.openDeleteDialog(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete-circle ")])]}}],null,true)},[_c('span',[_vm._v("Delete")])])],1)])]}},{key:"expanded-item",fn:function(ref){
            var headers = ref.headers;
            var item = ref.item;
return [_c('td',{staticClass:"pb-5",attrs:{"colspan":headers.length}},[_c('div',{staticClass:"ml-5 pt-5"},[_c('b',[_vm._v("List Role given to:")]),_vm._v(" "+_vm._s(item.name)+" "),_c('v-card',{attrs:{"outlined":"","flat":"","max-width":"20%"}},[_c('v-data-table',{attrs:{"headers":_vm.headerRoles,"items":item.roles,"items-per-page":item.roles ? item.roles.length : 20,"hide-default-footer":"","dense":""}})],1)],1)])]}}])}),_c('floating-button',{attrs:{"create-function":_vm.openDialog,"icon":'mdi-plus',"title":''}}),_c('UserDialog',{attrs:{"closeDialog":_vm.closeDialog,"isEditing":_vm.isEditing,"formData":_vm.formData,"dialog":_vm.dialog,"save":_vm.save,"loading":_vm.loading,"roles":_vm.roles,"searchOptions":_vm.searchRoles,"genders":_vm.gender,"user":_vm.user}}),_c('ConfirmDialog',{attrs:{"reject-function":_vm.closeDeleteDialog,"accept-function":_vm.deleteObject,"item":_vm.item,"isOpen":_vm.isOpen,"title":'Are you sure you want to delete?',"loading":_vm.loading}}),_c('userSearchDialog',{attrs:{"title":'Search ',"userDialogOpen":_vm.userDialogOpen,"closeDialog":_vm.closeSearchDialog,"search":_vm.findUser}}),_c('confirm-delete-resolve',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }