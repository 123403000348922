<template>
  <div class="users">
    <v-card-actions class="pa-0">
      <page-title :title="title" />

      <v-spacer></v-spacer>

      <!-- <add-button
        :create-function="openDialog"
        :icon="'mdi-view-grid-plus-outline'"
        :title="'ADD NEW'"
      /> -->
    </v-card-actions>
    <!-- <eFacilities :setActive="setActive" /> -->
    <e-facilities :set-data="setActive" />

    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="items"
      :server-items-length="params.total"
      :options.sync="pagination"
      :items-per-page="params.size"
      :single-expand="true"
      class="elevation-1"
      :no-data-text="'Data not available in this resource'"
      :footer-props="{
        'items-per-page-options': [20, 50, 100, 300, 500, -1],
      }"
    >
      <template v-slot:top>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-col cols="6" sm="12" md="4" class="pa-0">
            <v-text-field
              v-if="
                user &&
                  user.location &&
                  user.location.level &&
                  user.location.level.id != 5
              "
              v-model="selectedLoc"
              class
              flat
              hide-details
              maxlength="8"
              :label="'Search by Location'"
              prepend-icon="mdi-magnify"
              @click="searchUserByLocation"
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="6" sm="12" md="4" class="pl-4">
            <SearchField
              :search-function="searchUser"
              :prepend-icon="'mdi-magnify'"
              :label="'Search Data'"
              :append-icon="''"
            />
          </v-col>
        </v-card-title>
      </template>

      <template v-slot:item="{ item, isExpanded, expand }">
        <tr>
          <td>{{ item.index }}</td>
          <td>{{ item.fullName }}</td>
          <td>{{ item.gender ? item.gender.name : "" }}</td>
          <td>{{ item.NIN }}</td>
          <td>{{ item.cheque_number }}</td>
          <td>{{ item.birthdate }}</td>
          <td>{{ item.email }}</td>
          <td>{{ item.location.name }}</td>
          <td>{{ item.roles ? item.roles.length : "" }}</td>
          <td>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2"
                  @click="expand(!isExpanded)"
                >
                  mdi-format-list-bulleted
                </v-icon>
              </template>
              <span>List of Roles</span>
            </v-tooltip>
          </td>
          <td>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2"
                  @click="resetPassword(item)"
                  color="primary"
                >
                  mdi-lock-reset
                </v-icon>
              </template>
              <span>Reset Password</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2"
                  @click="openDialog(item)"
                  color="primary"
                >
                  mdi-pencil-box-multiple
                </v-icon>
              </template>
              <span>Update</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2"
                  @click="openDeleteDialog(item)"
                  color="red"
                >
                  mdi-delete-circle
                </v-icon>
              </template>
              <span>Delete</span>
            </v-tooltip>
            <!-- <ActionsButtons
                :update-function="openDialog"
                :delete-function="openDeleteDialog"
                :formData="item"
                :updateIcon="'mdi-pencil-box-multiple'"
                :deleteIcon="'mdi-delete-circle'"
                :updateButtonColor="''"
                :deleteButtonColor="''"
              /> -->
          </td>
        </tr>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pb-5">
          <div class="ml-5 pt-5">
            <b>List Role given to:</b>
            {{ item.name }}

            <!--Code:
              <em>
                <b>{{ item.code }}</b>
              </em>-->
            <v-card outlined flat max-width="20%">
              <v-data-table
                :headers="headerRoles"
                :items="item.roles"
                :items-per-page="item.roles ? item.roles.length : 20"
                hide-default-footer
                dense
              ></v-data-table>
            </v-card>
          </div>
        </td>
      </template>
    </v-data-table>

    <floating-button
      :create-function="openDialog"
      :icon="'mdi-plus'"
      :title="''"
    />
    <!-- <CreateButton
      :create-function="openDialog"
      :icon="' mdi-view-grid-plus-outline'"
    />-->
    <UserDialog
      :closeDialog="closeDialog"
      :isEditing="isEditing"
      :formData="formData"
      :dialog="dialog"
      :save="save"
      :loading="loading"
      :roles="roles"
      :searchOptions="searchRoles"
      :genders="gender"
      :user="user"
    />
    <ConfirmDialog
      :reject-function="closeDeleteDialog"
      :accept-function="deleteObject"
      :item="item"
      :isOpen="isOpen"
      :title="'Are you sure you want to delete?'"
      :loading="loading"
    />
    <userSearchDialog
      :title="'Search '"
      :userDialogOpen="userDialogOpen"
      :closeDialog="closeSearchDialog"
      :search="findUser"
    />
    <confirm-delete-resolve ref="confirm" />
  </div>
</template>

<script>
import UserDialog from "./Forms/UserDialog";
import userSearchDialog from "./Forms/UserDialogSearch";
import * as user from "./services";
import { get as fetchRoles } from "../roles/services";
import { v4 as uuidv4 } from "uuid";

export default {
  components: {
    UserDialog,
    userSearchDialog,
  },
  name: "User",
  data() {
    return {
      roles: [],
      selected: {},
      gender: [],

      datas: [],
      title: "Manage Users",
      isEditing: false,
      formData: {},
      userLocation: {},
      dialog: false,
      isOpen: false,
      userDialogOpen: false,
      loading: false,
      dialogVisible: false,
      params: {},
      item: null,
      items: [],
      headers: [
        {
          text: "No.",
          align: "start",
          sortable: false,
          value: "index",
          class: "primary--text",
        },
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "fullName",
          class: "primary--text",
        },
        {
          text: "Gender",
          align: "start",
          sortable: false,
          value: "gender",
          class: "primary--text",
        },

        {
          text: "NIDA ID",
          align: "start",
          sortable: false,
          value: "NIN",
          class: "primary--text",
        },

        {
          text: "CHECK NO",
          align: "start",
          sortable: false,
          value: "cheque_number",
          class: "primary--text",
        },

        {
          text: "DOB",
          align: "start",
          sortable: false,
          value: "birthdate",
          class: "primary--text",
        },
        {
          text: "Email",
          align: "start",
          sortable: false,
          value: "email",
          class: "primary--text",
        },
        {
          text: "Location",
          align: "start",
          sortable: false,
          value: "location.name",
          class: "primary--text",
        },
        {
          text: "Number of Roles",
          align: "start",
          sortable: false,
          value: "",
          class: "primary--text",
        },

        {
          text: "Roles",
          align: "start",
          sortable: false,
          value: "roles",
          class: "primary--text",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          class: "primary--text",
        },
      ],
      headerRoles: [
        {
          text: "Role Name",
          align: "start",
          sortable: false,
          value: "name",
          class: "primary--text",
        },
      ],
    };
  },

  computed: {
    user() {
      return this.$store.getters.getCurrentUser;
    },
    pagination: {
      get() {
        return this.params;
      },

      set(value) {
        // console.log(value)
        this.params.page = value.page;
        this.params.size =
          value.itemsPerPage != -1 ? value.itemsPerPage : this.params.total;

        this.params.total = this.params.total;

        this.init(this.params);
      },
    },
    selectedLoc: {
      get() {
        return this.userLocation ? this.userLocation.name : null;
      },
      set(val) {
        // console.log(val)
        val;
      },
    },
  },

  methods: {
    reset() {
      this.dialogVisible = true;
    },
    closeResetDialog() {
      this.dialogVisible = false;
    },

    setActive(data) {
      console.log("setActive", data);
    },

    init(params) {
      this.loading = true;
      user
        .get({
          ...params,
        })
        .then((response) => {
          this.loading = false;
          // console.log('resp', response.data[0].total)
          this.params.total = response.data[0].total;
          this.items = response.data[0]
            ? response.data[0].data
                .map((user) => ({
                  ...user,
                  fullName:
                    user.first_name +
                    " " +
                    user.middle_name +
                    " " +
                    user.surname,
                }))
                .sort(function(a, b) {
                  if (a > b) return 1;
                  return -1;
                })
                .map((item, index) => ({
                  ...item,
                  index: ++index,
                }))
            : [];
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    searchUserByLocation(data) {
      this.userDialogOpen = true;
    },
    closeSearchDialog() {
      this.userDialogOpen = false;
    },
    textTruncate(str, n) {
      return str.length > n ? str.substr(0, n - 1) + "..." : str;
    },
    openDialog(data = null) {
      this.dialog = true;
      if (data && data.id) {
        this.formData = data;
      } else {
        this.formData.uuid = uuidv4();
      }
    },

    closeDialog() {
      this.loading = false;
      this.dialog = false;
      this.formData = {};
      //this.getRoles();
    },

    closeDeleteDialog() {
      this.item = null;
      this.isOpen = false;
    },

    openDeleteDialog(item) {
      this.isOpen = true;
      this.item = item;
    },
    openUserDialog() {
      this.searchUserByLocation();
    },

    deleteObject() {
      this.loading = true;
      user
        .remove(this.item)
        .then(() => {
          this.finishDataActions();
        })
        .catch((err) => {
          if (err) {
            this.loading = false;
          }
        });
    },

    searchUser(search) {
      this.init({
        search: search ? search : null,
        size: this.params.size,
        total: this.params.total,

        //...this.params,
      });
    },
    findUser(data) {
      this.userLocation = data;
      let params = { "location.id": data.id };
      this.init(params);
      this.closeSearchDialog();
    },
    searchRoles(data) {
      this.getRoles({
        search: data ? data : null,
      });
    },
    save(data) {
      this.loading = true;
      if (data.id) {
        user
          .update(data)
          .then(() => {
            this.finishDataActions();
          })
          .catch((err) => {
            if (err) {
              this.loading = false;
            }
          });
      } else {
        user
          .create(data)
          .then(() => {
            // console.log('created')
            this.finishDataActions();
            //this.init(this.params)
          })
          .catch((err) => {
            if (err) {
              this.loading = false;
            }
          });
      }
    },

    resetPassword(data) {
      const payload = {
        email: data.email,
      };

      this.$refs.confirm
        .show({
          title: "Reset User Password",
          message: "Are you sure you want to Reset user Password?",
          loading: this.loading,
        })
        .then((result) => {
          if (result) {
            user.reset_password(payload);
          } else {
            this.loading = false;
          }
        });
    },
    finishDataActions() {
      this.loading = false;
      this.isOpen = false;
      this.closeDialog();
      this.init(this.params);
    },

    getRoles(params) {
      fetchRoles({ ...params, size: 1000 }).then((response) => {
        this.roles = response.data[0].data;
      });
    },
    getGenderList() {
      this.$store
        .dispatch("DataCategory/getCategoryOptions", "GENDER")
        .then((response) => {
          this.gender = response;
        });
    },
  },

  watch: {
    pagination: {
      handler() {
        this.loading = true;
        this.init(this.params);
      },
      update() {
        this.loading = true;
        this.init(this.params);
      },
      deep: true,
    },
  },
  created() {
    this.getRoles();
  },
  mounted() {
    this.getGenderList();
  },
};
</script>

<style scoped></style>
<style></style>
